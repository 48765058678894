import { featureFlags } from '@core/mocks/feature-flags.mock';
import {environment} from '@environments/environment';

declare global {
  interface Window {
    __featureFlags: any;
    logDevError: (message?: any, ...optionalParams: any[]) => void;
    logDevWarn: (message?: any, ...optionalParams: any[]) => void;
    _mfq: any;
  }
}

window._mfq = window._mfq || [];

if (!window.__featureFlags) {
  window.__featureFlags = featureFlags;
}

window.logDevError = (message?: any, ...optionalParams: any[]) => {
  !environment.production ? console.error(message, ...optionalParams) : null;
}

window.logDevWarn = (message?: any, ...optionalParams: any[]) => {
  !environment.production ? console.warn(message, ...optionalParams) : null;
}

import('./bootstrap')
	.catch(err => console.error(err));
