let mock: any = {
  effectiveTime: '14:40',
  blueFridayOffer: true,
  advance: {
    enable: true,
    enableMarket: true,
    workingTime: '9h às 19h',
    enableShortTermOffer: true,
    enableSimulation: true,
    advanceNewFeature: true,
    showFarolBanner: true
  },
  programmed: {
    enable: true,
    enableMarket: true,
    enableCancelation: true,
    feedback: {
      enable: true,
      link: 'https://pt.surveymonkey.com/r/9YGCYG9'
    }
  },
  search: {
    enableFeature: true
  },
  cancelOptions: [
    { value: 1, label: 'O prazo de 30 dias já é adequado para mim' },
    { value: 2, label: 'Preço acima do esperado' },
    { value: 3, label: 'Não solicitei a Antecipação' },
    { value: 4, label: 'Já possuo outras formas para receber em prazo mais curto' },
    { value: 5, label: 'Não atendeu minhas expectativas' },
    { value: 6, label: 'Outro', required: true }
  ],
  contractSuccessFeedbackMouseFlowId: 'uUqs5595tkuEmlsHyq2wmQ'
};

mock.faq= [
  {
    title: 'O que é a antecipação de recebíveis?',
    description:
      'É uma solução oferecida pela Cielo para que você, nosso cliente, possa antecipar o recebimento das suas vendas realizadas na função crédito (à vista e parcelado). Vamos exemplificar: Maria realizou uma venda em sua loja de roupas, no valor de R$ 900,00 parcelada em 3x e teria que aguardar 30, 60 e 90 dias para receber o valor total referente à esta transação, certo? Sim, mas ela possui a flexibilidade de receber este valor em até 1 dia útil realizando a antecipação de seus recebíveis com a Cielo.'
  },
  {
    title: 'Quais são os prazos de recebimento da antecipação?',
    description:
      'Para operações efetivadas no período de '+ mock.advance.workingTime +', a Cielo realiza os pagamentos ainda no mesmo dia! Depois deste horário, o pagamento é realizado no próximo dia útil.'
  },
  {
    title: 'Como eu posso fazer a antecipação?',
    description:
      'É bem simples! Você tem um leque de canais à sua disposição e nós ensinamos o passo a passo para concluir.<ul><li>Site </li><li>App </li><li>Central de relacionamento: (11) 4002 5472 </li></ul><a href="https://www.youtube.com/watch?v=hDGOBSrHgOE" target="_blank" data-analyticscategory="Institucional | LP Arv" data-analyticsaction="FAQ" data-analyticslabel="Link | Assista ao vídeo">Assista ao vídeo</a>, para saber mais!'
  },
  {
    title: 'Onde eu vou receber o dinheiro das vendas antecipadas?',
    description:
      'O cliente pode ter mais de um domicílio bancário para o recebimento dos valores antecipados e no processo de antecipação pode selecionar aquele que preferir receber. Simples, né?'
  },
  {
    title: 'Posso tornar uma antecipação recorrente?',
    description:
      'Claro! Aqui na Cielo você pode antecipar seus recebíveis de vendas de maneira avulsa, quando desejar, e programada, na qual os valores são antecipados todos os dias automaticamente. E você pode cancelar a programação quando quiser.'
  },
  {
    title: 'Qual a diferença entre antecipação e empréstimo?',
    description:
      'O adiantamento de recebíveis envolve valores que “já existem”, ou seja, é uma operação financeira que trata de receitas já geradas e um dinheiro que já é seu - e que apenas não estão disponíveis imediatamente. Já o empréstimo é uma operação em que há a necessidade de obtenção de recursos financeiros de uma instituição, se comprometendo com o pagamento do que foi emprestado em um prazo determinado e geralmente, com juros e pagamento de IOF.'
  }
];

export const anticipationMock = mock;
