import packageJson from '../../package.json';

export const environment = {
  production: false,
  urlConfig: '/site/vars',
  urlApi: '',
  recaptchaUrl: 'https://www.google.com/recaptcha/enterprise.js?render=#{token}',
  gtmContainerUrl:
    'https://www.googletagmanager.com/gtm.js?id=GTM-WXKKC9W&l=dataLayer&gtm_auth=d31IhmAWkt81wjxDvJA3pQ&gtm_preview=env-78&gtm_cookies_win=x',
  gaId: 'UA-8516386-25',
  localhost: false,
  accessBank:
    'https://www.cielo.com.br/VOL/pageFlows/acessoAreaRestritaBancos/AcessoAreaRestritaBancosController.jpf',
  onboardingAPIAdquirencia: 'https://onboarding-hml.cielo.com.br/site-api-adquirencia',
  mfeUrls: {
    'angular-mfe-example': '/angular-mfe-example',
    'react-mfe-example': '/react-mfe-example',
    recebiveis: 'https://extc-cnl-extrato-vendas-mfe.extc.hml.cieloaws',
    'gestao-acessos': '/gestao-acessos',
    'gestao-open-finance': '/gestao-open-finance',
    'gestao-pix': '/gestao-pix',
    conciliador: '/conciliador-mfe',
    'recebimento-automatico': '/recebimento-automatico',
    'configuracoes-pix': '/configuracoes-pix',
    'onboarding-credenciamento': 'https://onboarding-hml.cielo.com.br',
    'cancelamento-vendas': '/cancelamento-vendas',
    'taxas-planos': '/taxas-planos',
    'transacoes-ecommerce': '/transacoes-ecommerce',
    antecipacao: '/antecipacao',
    'identidade-digital': '/identidade-digital',
    'simulador-vendas': '/simulador-vendas',
    'credenciais-ecommerce': '/credenciais-ecommerce',
    acessos: '/acessos',
    farol: 'https://gvv-farol-core-mfe-hml-cloud.enterprisetrn.hdevelo.com.br',
    'orientacoes-processos': '/orientacoes-processos',
    'central-notificacoes': '/central-notificacoes',
    'contestacao-vendas': '/contestacao-vendas'
  },
  environmentName: 'hml',
  version: packageJson.version
};
